<template>
  <div class="blog-wrapper">
    <div class="hero-header-section">
      <HeaderBlog :items="posts" />
    </div>
    <div class="last-article-wrapper">
      <div class="last-article__title">
        آخرین مقالات
      </div>
      <div class="last-article__card-wrapper">
        <div id="card" v-for="post in posts" :key="post.id">
          <div class="card-container" @click="goArticle(post.id)">
            <img class="card-image" :src="post.image" alt="" />
            <div class="body">
              <div class="header">
                <div class="header">{{ post ? post.title : "----" }}</div>
                <div class="information">
                  <img
                    class="icon"
                    src="../assets/images/carbon_view-filled.svg"
                    alt=""
                  />
                  <span class="view">{{ post ? post.view : 0 }}</span>
                  <div class="date">
                    {{ post.jalali_created_at_date }}
                  </div>
                </div>
              </div>
              <div class="content">
                {{ post ? post.body : "----" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="show-more-wrapper">
        <div class="show-more">
          <div class="title">
            مشاهده ی بیشتر
          </div>
          <div class="show-more__icon">
            <img
              src="../assets/images/show-more-icon.svg"
              alt=""
              class="show-more_icon-image"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="footer-panel__wrapper">
      <Footer />
    </div>
  </div>
</template>

<script>
import HeaderBlog from "@/components/Blog/HeaderBlog";
import Footer from "@/components/Footer";
import axios from "axios";

export default {
  components: { Footer, HeaderBlog },
  data() {
    return {
      posts: []
    };
  },
  mounted() {
    this.getPosts();
  },
  methods: {
    async getPosts() {
      try {
        let response = await axios("/blog");
        this.posts = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    goArticle(id) {
      this.$router.push(`/article/${id}`);
    }
  }
};
</script>

<style scoped>
.blog-wrapper {
  width: 100%;
}

.hero-header-section {
  width: 100%;
  height: 100vh;
}

.last-article-wrapper {
  margin-top: 60px;
}

.last-article-wrapper {
  /*padding: 0 125px;*/
  padding: 10px;
}

.last-article__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  color: #121212;
  margin-bottom: 20px;
  font-family: "Vazir Medium FD";
}

.card-container {
  max-width: 343px;
  width: 100%;
  min-height: 331px;
  height: fit-content;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  overflow: hidden;
}

.card-image {
  max-width: 343px;
  min-height: 228px;
  width: 100%;
}

.body {
  padding: 5px 15px 17px 17px;
}

.header {
  font-style: normal;
  font-weight: 500;
  /*font-size: 20px;*/
  font-size: 16px;
  line-height: 31px;
  text-align: right;
  margin-bottom: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "Vazir Medium FD";
}

.information {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*width: 35%;*/
}

.content {
  font-style: normal;
  font-weight: normal;
  /*font-size: 14px;*/
  font-size: 12px;
  line-height: 22px;
  text-align: right;
  max-height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "Vazir Medium FD";
  /*min-height: 44px;*/
  /*min-width: 290px;*/
}

.icon {
  width: 15px;
  height: 15px;
  opacity: 0.9;
  margin-left: 5px;
}

.view {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  /*font-size: 10px;*/
  line-height: 19px;
  text-align: right;
  color: #070d59;
  opacity: 0.9;
  margin-left: 7px;
}

.date {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  text-align: right;
  color: #070d59;
  opacity: 0.9;
}

.last-article__card-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.show-more-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.show-more {
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.show-more__icon {
  text-align: center;
}

.footer-panel__wrapper {
  margin-top: 120px;
}

.title {
  font-family: "Vazir Medium FD";
}

#card {
  margin-bottom: 20px;
  cursor: pointer;
}

@media (min-width: 576px) {
  .view {
    font-size: 10px;
  }

  .content {
    font-size: 14px;
  }

  .header {
    font-size: 20px;
  }

  .last-article__card-wrapper {
    /*display: flex;*/
    justify-content: center;
    /*flex-wrap: wrap;*/
  }
}

@media (min-width: 768px) {
  .last-article__card-wrapper {
    /*display: flex;*/
    justify-content: space-between;
    /*flex-wrap: wrap;*/
  }
}

@media (min-width: 960px) {
}

@media (min-width: 1140px) {
  .last-article-wrapper {
    padding: 0 125px;
    /*padding: 10px;*/
  }
  #card {
    margin-bottom: 80px;
  }
}
</style>

<!--دوشنبه ۱۱ مهر۱۳۹۶-->
