<template>
  <div class="header-blog">
    <div class="background-image"></div>
    <div class="header-blog__wrapper">
      <div class="header-blog__title">
        آخرین خبرهای ارز دیجیتال را با ما دنبال کنید.
      </div>
      <div class="header-slider__wrapper">
        <Carousel3d
          :count="items.length"
          class="slider"
          display="8"
          border="0"
          height="330px"
          :clickable="true"
        >
          <Slide v-for="(item, i) in items" :index="i" :key="i">
            <div class="card" @click.prevent="goArticle(item.id)">
              <img :src="item.image" alt="" height="100%" class="image" />
              <div class="title">{{ item.title }}</div>
            </div>
          </Slide>
        </Carousel3d>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";

export default {
  name: "HeaderBlog",
  props: {
    items: {
      required: true
    }
  },
  components: {
    Carousel3d,
    Slide
  },
  methods: {
    goArticle(index) {
      console.log(index);
    }
  }
};
</script>

<style scoped>
.header-blog {
  position: relative;
  background-size: 100% 100%;
  width: 100%;
  height: 100vh;
}

.header-blog::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -30px;
  z-index: -1;

  display: block;
  background-image: url("../../assets/images/blog-background.png");
  background-size: cover;
  width: 100%;
  height: 100vh;

  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.header-blog__title {
  position: absolute;
  font-weight: 500;
  font-size: 34px;
  line-height: 53px;
  text-align: center;
  color: #ffffff;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
}

.slider {
  position: absolute;
  top: 260px;
  left: 50%;
  transform: translateX(-46%);
}

.card {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  /*border-radius: 10px;*/
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  /*width: 100%;*/
  transform: translate(-45%, -50%);
  width: 85%;
  text-align: right;
}
/*.header-slider__wrapper {*/
/*  margin-top: 200px;*/
/*  position: absolute;*/
/*  top: 390px;*/
/*  left: 50%;*/
/*  !*transform: translateX(-50%);*!*/
/*}*/

.header-slider__wrapper {
  display: none;
}

@media (min-width: 768px) {
  .header-slider__wrapper {
    display: block;
  }
}
</style>
